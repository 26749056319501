<template>
  <div class="root_store">
    <Navbar value='5'/>
    <NavTabs />
    <div class="store-head" @click="onClick">Store</div>
    <router-view :key="$route.fullPath"></router-view>
    <!-- footer -->
    <FooterYic />
  </div>
</template>
<script>
import Navbar from "../navbar/Navbar.vue";
import NavTabs from "./NavTabs.vue";
import Items from "./LandingPageItems/Items.vue";
import FooterYic from '../homepage/footer.vue';
import Ripple from "vue-ripple-directive";

export default {
  name: 'Store',
  components: {
    Items,
    FooterYic,
    Navbar,
    NavTabs,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClick() {
      this.$router.push({ name: 'store' });
    },
  },
};
</script>
<style scoped>
.root_store {
  background: #fff;
  width: 100%;
}

.store-head {
  display: none;
}

/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
  .store-head {
    display: flex;
    background: #e87613;
    /* background: #4385f5; */
    color: #fff;
    align-items: center;
    justify-content: center;
    height: 3rem;
    font-weight: 600;
    font-size: 1.5rem;
    /* text-transform: uppercase; */
    margin: 0.3rem;
    cursor: default;
  }
}
</style>