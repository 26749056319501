<template>
  <div class="xy">

    <!-- Category Large Screen -->

    <div class="root_cat">
      <div class="cat_b" v-for="category in productCategories" :key="category.id">
        <Items :category="category" />
      </div>
    </div>

    <!-- Category Small Screens -->
    <!-- <div class="root_cat_sm">
      <div class="title_cat">Category : </div>
      <b-dropdown v-if="selectedCategoryStore" v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        :text="selectedCategoryStore.name" variant="warning">
        <b-dropdown-item v-for="(item, indx) in productCategories" :key="indx" @click="onClick(item)">
          <b>{{ item.name }}</b>
        </b-dropdown-item>
      </b-dropdown>
    </div> -->

    <!-- SubCategory -->
    <!-- {{ selectedSubCategory }} -->
    <!-- {{ selectedSubCategoryName }} -->
    <!-- <div class="root_subc">
      <div class="title_subc">
      </div>
      <div class="subcategory__">
        <b-dropdown class="drop_down__" v-ripple.400="'rgba(186, 191, 199, 0.15)'" :text="selectedSubCategoryName"
          variant="warning">
          
          <b-dropdown-item @click="onClick2(null)">
            <b>All</b>
          </b-dropdown-item>
          <b-dropdown-item v-for="(item, indx) in subCategories" :key="indx" @click="onClick2(item.id)">
            <b>{{ item.name }}</b>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div> -->

  </div>
</template>

<script>
import { BModal, VBModal, BAlert, BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapMutations, mapActions, mapState } from "vuex";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BDropdown, BDropdownItem,
} from "bootstrap-vue";
// import Items from './Items.vue';

export default {
  name: 'Categories',
  components: {
    BDropdown, 
    BDropdownItem,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    Items: () => import('./Items.vue'),
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    };
  },
  methods: {
    search(nameKey, myArray) {

      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].id == nameKey) {
          return myArray[i].name;
        }
      }
    },
    async onClick(category) {
      await this.reloadCategories();
    },
    async onClick2(subCategoryId) {
      const _id = this.selectedCategoryStore.id;
      await this.reloadCategories();
    },
    ...mapActions({
      fetchProductCategories: "storeProducts/fetchProductCategories",
      setSelectedCategory: "storeProducts/setSelectedCategory",
      populateSelectedCategoryFromId: "storeProducts/populateSelectedCategoryFromId",
      fetchSubCategories: "storeProducts/fetchSubCategories",
      setSelectedSubCategory: "storeProducts/setSelectedSubCategory",
      setCurrentPage: "storeProducts/setCurrentPage",
      getCategoriesWithTitle: "storeProducts/getCategoriesWithTitle",
      fetchProducts: "storeProducts/fetchProducts",
      getVariants: "storeProducts/getTempVariant",
    }),
    async reloadCategories() {
      await this.fetchProductCategories();
      if (this.$route.query.selectedCategory) { // category is given..
        const id = this.$route.query.selectedCategory;
        await this.populateSelectedCategoryFromId({ id })
        await this.fetchSubCategories();

        // subcategory start
        if (this.$route.query.selectedSubCategory) {// subcategory also in url..
          const _id = this.$route.query.selectedSubCategory;
          await this.setSelectedSubCategory({ subCategoryId: _id });
        } else {
          await this.setSelectedSubCategory({ subCategoryId: null });
        }
        // subcategory end
      } else {
        await this.populateSelectedCategoryFromId({ id: 'null' })
        await this.fetchSubCategories();
        await this.setSelectedSubCategory({ subCategoryId: null });
      }

      // current page start
      if (window.location.hash) {
        let pageNum = window.location.hash;
        pageNum = pageNum.slice(1);
        await this.setCurrentPage({ pageNum })
      } else {
        await this.setCurrentPage({ pageNum: 1 })
      }
      // current page end

    }
  },
  computed: mapState({
    productCategories(state) {
      if (state.storeProducts.productCategories) {
        return state.storeProducts.productCategories;
      }
      // return this.list;
    },
    selectedCategoryStore(state) {
      return state.storeProducts.selectedCategory;
    },
  }),
  async mounted() {
    await this.fetchProductCategories();
    await this.reloadCategories();
    await this.getCategoriesWithTitle()
  },
  watch:{
  }
};
</script>

<style scoped>
.root_cat {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  padding-top: 0;
  padding-right: 0;
}

.cat_b {
  font-size: 2rem;
  color: #000;
  font-weight: 600;
}

.bttn2 {
  min-width: 160px;
  max-height: 40px;
  border: 1px solid #4385f5;
  background: transparent;
  color: #4385f5;
}

.active-btn {
  background: #4385f5;
  color: #fff;
}

.root_cat_sm {
  display: none;
}

.drop_down__ {
  min-width: 200px;
}

.subcategory__{
  margin-left: 8rem;

}
.sort_filter_search{
  margin: 1rem 4rem 1rem 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.sort_filter_search>*{
  margin: 1rem;
}
.sort__{
  width: 30%;
  border: 1px solid #000080;
  border-radius: 2rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.search_items {
  width: 70%;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.search_input_box {
  border: none;
  height: 100%;
  border: 1px solid #000080;
  border-radius: 2rem;
  padding: 0.5rem;
  padding-left: 1rem;
  color: #000;
}


/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 800px) {
  .root_cat {
    padding: 1rem;
    padding-top: 0;
    padding-right: 1rem;
  }

  .subcategory__{
    margin-left: auto;
    width: max-content;
    margin-right:0.3rem;
  }
  
  .root_cat_sm {
    display: flex;
    flex-wrap: wrap;
    background: #4385f5;
    color: #fff;
    align-items: center;
    height: 3rem;
    height: max(max-content, 3rem);
    font-weight: 500;
    font-size: 1rem;
    margin: 0.3rem;
    padding: 0 0.7rem;
  }

  .title_cat {
    margin-right: 0.5rem;
  }

  select {
    background: transparent;
    outline: none;
    border: none;
    color: #fff;
  }
}
</style>