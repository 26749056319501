<template>
  <div class="root_tabs">
      <div class="tab_name" @click="viewAllProducts">All Products</div>
      <div v-for="category in productCategories" :key="category.id" class="categories">
        <!-- <div class="tab_name" @click="onClick(category)">{{category.name}} <i class="fa-solid fa-chevron-down"></i></div> -->
        <div>
          <b-dropdown v-ripple.400="'rgba(30, 30, 30, 0.15)'" :text="category.name" variant="flat-secondary" id="tab_x" @click="onClick(category)" class="tab_name">
            <b-dropdown-item v-for="n in subCategories.get(category.name)" :key="n.id">
              <div class="catr" @click="viewItemsAccordingToCategory(n, category)">
                {{ n.name }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
  </div>
</template>
<script>
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  components: {
    BDropdown,
    BDropdownItem
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeNavId: 1,
      list: [
        { name: "Home", comp: "store" },
      ],
      showCategoryDropdown: false
    };
  },
  methods: {
    clickHandler(nam, id) {
      if (id) this.activeNavId = id;
      else this.activeNavId = -1;
      this.$router.push({ name: nam });
    },
    async onClick(category) {
      this.showCategoryDropdown = !this.showCategoryDropdown
      await this.fetchSubCategories();
    },
    ...mapActions({
      fetchProductCategories: "storeProducts/fetchProductCategories",
      setSelectedCategory: "storeProducts/setSelectedCategory",
      fetchSubCategories: "storeProducts/fetchSubCategories",
      setSelectedCategoryProducts: "storeProducts/setSelectedCategoryProducts",
      setSelectedSubCategory: "storeProducts/setSelectedSubCategory",
    }),
    async viewItemsAccordingToCategory(subCategory, selectedCategory){
      localStorage.setItem("category", JSON.stringify(selectedCategory))
      localStorage.setItem("subCategory", JSON.stringify(subCategory))
      await this.setSelectedCategoryProducts({selectedCategory: selectedCategory})
      await this.setSelectedSubCategory({subCategory: subCategory})
      this.$router.push(`/store/category/${selectedCategory.name}`).catch(() => {});
    },
    viewAllProducts(){
      this.$router.push({name: 'store'})
    }
  },
  computed: mapState({
    productCategories(state) {
      if (state.storeProducts.productCategories) {
        return state.storeProducts.productCategories;
      }
      return this.list;
    },
    selectedCategory(state) {
      if (state.storeProducts.selectedCategory) {
        return state.storeProducts.selectedCategory;
      } else return null;
    },
    subCategories(state){
      if(state.storeProducts.subCategories){
        return state.storeProducts.subCategories;
      }
      else{
        return null;
      }
    }
  }),
  async mounted() {
    await this.fetchProductCategories();
    await this.fetchSubCategories();
  },
};
</script>

<style scoped>
.root_tabs {
  background-color: #150035;
  display: flex;
  height: 48px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-left: 5rem;
  padding-right: 5rem;
}
.tab_name{
  color: #FFFFFF;
  font-size: 1rem;
  cursor: pointer;
  z-index: 1;
}
.tab_name:hover {
  text-decoration: underline;
  z-index: 10;
  color: #FFB000;
  font-weight: 500;
  box-shadow: inset;
}
.categories{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}



/*========================= Media Queries (Meduim devices) =========================*/

/*========================= Media Queries (Small devices) =========================*/
@media screen and (max-width: 600px) {
  .root_tabs {
    display: none;
  }
}
</style>



<style>
#tab_x>button {
  color: #f5f5f5;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  background: none;
}
#tab_x>button:hover{
  text-decoration: underline;
  z-index: 10;
  color: #FFB000;
  font-weight: 500;
  box-shadow: inset;
}
</style>